export const defaultConfig = {
  input: {
    type: 'input',
    name: 'input',
    label: '文本框',
    disabled: false,
    allowClear: true,
    inputType: 'text',
    maxlength: 100,
  },
  textarea: {
    type: 'textarea',
    name: 'textarea',
    label: '文本域',
    allowClear: false,
    disabled: false,
    maxlength: 1000,
  },
  number: {
    type: 'number',
    name: 'number',
    label: '数字进步器',
    disabled: false,
  },
  select: {
    type: 'select',
    name: 'select',
    label: '下拉选',
    disabled: false,
    allowClear: true,
    datasourceType: 'local',
    datasource: 'select',
    mode: 'default',
    maxTagCount: 1,
    valueKey: 'value',
    labelKey: 'label',
  },
  // treeselect: {
  //   type: 'treeselect',
  //   name: 'treeselect',
  //   label: '下拉树',
  //   disabled: false,
  //   allowClear: true,
  //   datasourceType: 'local',
  //   datasource: 'treeselect',
  //   mode: 'multiple',
  //   valueKey: 'id',
  //   labelKey: 'name',
  // },
  // cascader: {
  //   type: 'cascader',
  //   name: 'cascader',
  //   label: '级联选择器',
  //   disabled: false,
  //   allowClear: true,
  //   datasourceType: 'local',
  //   datasource: 'cascader',
  //   valueKey: 'id',
  //   labelKey: 'name',
  // },
  timepicker: {
    type: 'timepicker',
    name: 'timepicker',
    label: '时间选择器',
    disabled: false,
    allowClear: true,
    format: 'HH:mm',
    valueFormat: 'HH:mm',
  },
  monthpicker: {
    type: 'monthpicker',
    name: 'monthpicker',
    label: '月份选择器',
    disabled: false,
    allowClear: true,
    format: 'YYYY-MM',
    valueFormat: 'YYYY-MM',
  },
  datepicker: {
    type: 'datepicker',
    name: 'datepicker',
    label: '日期选择器',
    disabled: false,
    allowClear: true,
    mode: 'time',
    showTime: true,
    valueFormat: 'YYYY-MM-DD HH:mm:ss',
  },
  rangepicker: {
    type: 'rangepicker',
    name: 'rangepicker',
    label: '时间范围选择器',
    disabled: false,
    allowClear: true,
    showTime: true,
    valueFormat: 'YYYY-MM-DD HH:mm:ss',
  },
  checkbox: {
    type: 'checkbox',
    name: 'checkbox',
    label: '多选框',
    disabled: false,
    allowClear: true,
    datasourceType: 'local',
    datasource: 'checkbox',
    valueKey: 'value',
    labelKey: 'label',
    mode: 'multiple',
  },
  radio: {
    type: 'radio',
    name: 'radio',
    label: '单选框',
    disabled: false,
    allowClear: true,
    datasourceType: 'local',
    datasource: 'radio',
    valueKey: 'value',
    labelKey: 'label',
  },
  weight: {
    type: 'weight',
    name: 'weight',
    label: '重量 ',
    disabled: false,
    addonAfter: 'Kg',
  },
  volume: {
    type: 'volume',
    name: 'volume',
    label: '容积 ',
    disabled: false,
    addonAfter: 'L',
  },
};

export const defaultRules = {
  input: [
    {
      required: false,
      message: '请填写必填项',
      type: 'string',
    },
  ],
  textarea: [
    {
      required: false,
      message: '请填写必填项',
      type: 'string',
    },
  ],
  number: [
    {
      required: false,
      message: '请填写必填项',
      type: 'number',
    },
  ],
  select: [
    {
      required: false,
      message: '请选择必填项',
      type: 'array',
    },
  ],
  // treeselect: [
  //   {
  //     required: false,
  //     message: '请选择必填项',
  //     type: 'array',
  //   },
  // ],
  cascader: [
    {
      required: false,
      message: '请选择必填项',
      type: 'array',
    },
  ],
  datepicker: [
    {
      required: false,
      message: '请选择必填项',
      type: 'string',
    },
  ],
  rangepicker: [
    {
      required: false,
      message: '请选择必填项',
      type: 'array',
    },
  ],
  timepicker: [
    {
      required: false,
      message: '请选择必填项',
      type: 'string',
    },
  ],
  monthpicker: [
    {
      required: false,
      message: '请选择必填项',
      type: 'string',
    },
  ],
  checkbox: [
    {
      required: false,
      message: '请选择必填项',
      type: 'array',
    },
  ],
  radio: [
    {
      required: false,
      message: '请选择必填项',
      type: 'string',
    },
  ],
};

export const defaultValue = {
  input: '',
  textarea: '',
  select: undefined,
  treeselect: [],
  cascader: [],
  datepicker: '',
  rangepicker: [],
  timepicker: '',
  monthpicker: '',
};

export const defaultPlaceholder = {
  input: '请填写',
  textarea: '请填写',
  select: '请选择',
  // treeselect: '请选择',
  cascader: '请选择',
  datepicker: '请选择',
  rangepicker: '请选择',
  timepicker: '请选择',
  monthpicker: '请选择',
  weight: '请填写',
  volume: '请填写',
};
export const defaultOptions = {
  select: [
    {
      label: '选项一',
      name: '选项一',
    },
    {
      label: '选项二',
      name: '选项二',
    },
    {
      label: '选项三',
      name: '选项三',
    },
  ],
  checkbox: [
    {
      label: '选项一',
      name: '选项一',
      value: '选项一',
    },
    {
      label: '选项二',
      name: '选项二',
      value: '选项二',
    },
    {
      label: '选项三',
      name: '选项三',
      value: '选项三',
    },
  ],
  radio: [
    {
      label: '正常',
      name: '正常',
      value: '正常',
    },
    {
      label: '异常',
      name: '异常',
      value: '异常',
    },
  ],
};
